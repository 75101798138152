@import '../variables.modules';

nav {
  transition: $transition;
}

.active {
  border-bottom: 5px solid;
  border-image-slice: 1;
}

li {
  transition: $transition;

  &:hover {
    transform: translateY(-3px);
    transition: $transition;
  }
}

.link:hover {
  color: unset;
}

.navbar.fixed {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
}
