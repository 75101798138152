@import '../../variables.modules';

h1 {
  font-size: 2rem;
  text-align: center;
  @media only screen and (min-width: 940px) {
    font-size: 3rem;
    text-align: left;
  }
}

h2 {
  font-size: 1.25rem;
  text-align: center;
  @media only screen and (min-width: 940px) {
    font-size: 2rem;
    text-align: left;
  }
}

.hand {
  animation-name: wave;
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
  transform-origin: 70% 70%;
  display: inline-block;
}

a { 
  color: $dark;
  transition: color 200ms ease;
}

a:hover {
  color: $lightgray;
  transition: color 200ms ease;
}

@keyframes wave {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(14deg);
  }
  20% {
    transform: rotate(-8deg);
  }
  30% {
    transform: rotate(14deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.arrow {
  text-align: center;
  margin: 50%;
}
.bounce {
  -moz-animation: bounce 2s infinite;
  -webkit-animation: bounce 2s infinite;
  animation: bounce 2s infinite;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

.team {
  text-align: center;
}

.teamText {
  margin-left:20%;
  margin-right:20%;
}

/* Media query for mobile devices with max-width of 767px */
@media (max-width: 767px) {
  .teamText {
    margin-left: 5%;
    margin-right: 5%;
  }
}